// Photo and gif path portfolio
import project1 from './assets/portfolio/smart-engineering.png';
import project2 from './assets/portfolio/spacex-missions.gif';
import project3 from './assets/portfolio/box-office.png';
import project4 from './assets/portfolio/servisimi.png';
import project5 from './assets/portfolio/garden.png';
import project6 from './assets/portfolio/csharp.jpg';
import project7 from './assets/portfolio/note.png';
import project8 from './assets/portfolio/tic-tac-toe.gif';
import project9 from './assets/portfolio/ik-hausbetreuung.png';
import project10 from './assets/portfolio/kerkomjeshterin.png';
import project11 from './assets/portfolio/foundlostx.png';
import other from './assets/portfolio/other.png';

// Video Path
import smartEngineering from './assets/video/smart-engineering.tech.mp4';
import spaceX from './assets/video/spaceX.mp4';
import boxOffice from './assets/video/box-office.mp4';
import alservisim from './assets/video/alservisim.mp4';
import gardenOld from './assets/video/gardenOld.mp4';
import note from './assets/video/note.mp4';
import csharp from './assets/video/projekti-csharp.mp4';

import tickTackToe from './assets/video/ticTacToe.mp4';
import garden from './assets/video/ik-hausbetreuung.mp4';
export const video = [
  {
    id: 1,
    title: 'smart-engineering.tech - HTML, CSS, JavaScript',
    src: smartEngineering,
  },
  {
    id: 2,
    title: 'SpaceX Missions - React, GraphQL, Apollo/Client, Material UI',
    src: spaceX,
  },
  {
    id: 3,
    title: 'Box Office - ReactJs, styled-components, Rest API',
    src: boxOffice,
  },
  {
    id: 4,
    title: 'Al Servisim - HTML, CSS, JavaScript',
    src: alservisim,
  },
  {
    id: 5,
    title: 'Garden Project - HTML, CSS, JavaScript',
    src: gardenOld,
  },
  {
    id: 6,
    title: 'Arra Academy - C# & SQL',
    src: csharp,
  },
  {
    id: 7,
    title: 'Note - ReactJs',
    src: note,
  },
  {
    id: 8,
    title: 'Tic-Tac-Toe Game - ReactJs',
    src: tickTackToe,
  },
  {
    id: 9,
    title: 'ik-hausbetreuung - HTML, CSS, JavaScript',
    src: garden,
  },
];

export const portfolios = [
  {
    id: 1,
    src: project1,
    href: 'https://smart-engineering.tech/',
  },
  {
    id: 2,
    src: project2,
    href: 'https://github.com/bernardkas/react-graphql-spaceX',
  },
  {
    id: 3,
    src: project3,
    href: 'https://github.com/bernardkas/box-office',
  },
  {
    id: 4,
    src: project4,
    href: '',
  },
  {
    id: 5,
    src: project5,
    href: 'https://github.com/bernardkas/Garden',
  },
  {
    id: 6,
    src: project6,
    href: '',
  },
  {
    id: 7,
    src: project7,
    href: 'https://github.com/bernardkas/Note',
  },
  {
    id: 8,
    src: project8,
    href: 'https://github.com/bernardkas/tictactoe',
  },
  {
    id: 9,
    src: project9,
    href: 'https://ik-hausbetreuung.at/',
  },
  {
    id: 10,
    src: project10,
    href: 'https://www.kerkomjeshter.com/',
  },
  {
    id: 11,
    src: project11,
    href: 'https://foundlostx.vercel.app/',
  },
  {
    id: 12,
    src: other,
    href: 'https://github.com/bernardkas?tab=repositories',
  },
];
